import React, { useEffect, useRef, useState } from 'react'

function EditNote({editNote,note,onChange,edit}) {
  const [data,setData]=useState("");
  const [isEdit,setIsEdit]=useState(false);
  useEffect(()=>{
     setData(note);
     if(edit){
       setIsEdit(edit);
     }   
  },[note,edit])
  return (
    <div className='flex items-center justify-between'>
    <div className='flex flex-shrink-1'>
      <label>note: </label>
      <input
        autoFocus={true}
        onChange={(e) => {
          setData(e.target.value);
          onChange && onChange(e.target.value);
        }}
        disabled={!isEdit}
        className={`${
          isEdit ? "border-2" : "border"
        } rounded-full w-full h-full ml-1 px-1 font-normal disabled:text-black text-center text-black text-lg py-1 overflow-auto`}
        value={data}
      />
    </div>
    <div className='flex flex-grow-1'>
      <button
        onClick={() => {
          if (isEdit) {
            editNote(data);
          }
          setIsEdit(!isEdit);
        }}
        className={`border rounded-lg px-2 py-1 ${
          !isEdit ? "text-blue-500" : "bg-green-500 text-white"
        }`}
      >
        {isEdit ? "lưu" : "sửa"}
      </button>
      {isEdit && (
        <button className=' p-1 bg-red-400 text-white  rounded-xl' type='button' onClick={() => setIsEdit(false)}>
          huỷ
        </button>
      )}
    </div>
  </div>
  
  )
}

export default EditNote