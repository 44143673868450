import { updateHd } from "./hoaDonSlice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
// ... (code)

 
  
let initialState={
    khachHang:[],
}
 let khachHangSlice=createSlice({
    name:"khachHangSlice",
    initialState,
    reducers:{
        setKhachHang:(state,{payload})=>{
            state.khachHang=payload;
        },
        updateKhachHang:(state,{payload})=>{
            let clone=[...state.khachHang];
            let index=state.khachHang.findIndex((item)=>{return item.idKh.toString()===payload.idKh.toString()});
            if(index>-1){
                 clone[index]=payload;
            }
            state.khachHang=clone;
        },
        updateHdOfKh:(state,{payload})=>{
            
            let clone=state.khachHang;
            let {idKh,hd}=payload;
            let indexKh=clone.findIndex((item)=>{return item.idKh?.toString()===idKh.toString()});
            if(indexKh>-1){
                let kh=clone[indexKh];
               let indexHd=kh.hoaDons.findIndex((itemHd)=>{
                        return itemHd.maHd.toString()==hd.maHd.toString();
               })
               if(indexHd>-1){
                  kh.hoaDons[indexHd]=hd;
               }
            }
            state.khachHang=clone;
        }
    }
 })
 export let {setKhachHang,updateKhachHang,updateHdOfKh}=khachHangSlice.actions;
 export default khachHangSlice.reducer;