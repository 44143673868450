import React from 'react'
import { useState } from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { checkRes } from '../../../unity'
import Header from '../../components/Header'
import { api_2024 } from '../../services/apiService'
import ItemHd from '../DanhSachHoaDonsKh/ItemHd'
import KhachHang from '../home/components/KhachHang'

function PageCongNo() {
  const [data,setData]=useState([]);  
  const listKh=useSelector((state)=>{
     let dataKh= state.khachHangSlice.khachHang;
      return dataKh.filter((kh)=>{
           for(let hd of kh.hoaDons){
                if(hd.thanhToan=="x"){
                  return true;
                }
           }
           return false;
      })
  })
  const [thanhToan,setThanhToan]=useState([]);
    useEffect(()=>{
      api_2024.get("khachHang",{include:[{as:"hoaDons",where:{thanhToan:"x"},include:[{as:"chiTietHoaDons"}]}]}).then(
        (res)=>{
          let value=checkRes(res);
          if(value){
                setData(value);
          }else{
            alert(res.data.error)
          }
        }
      )
    },[])
    const editThanhToan=(hd)=>{
      let clone=[...data];
      let findKh=clone.findIndex((kh)=>{ return kh.idKh.toString()==hd.idKh.toString()});
      if(findKh>-1){
         let kh=clone[findKh];
         let findhd=kh.hoaDons?.findIndex((hd)=>{return hd.maHd.toString()===hd.maHd.toString()});
         if(findhd>-1){
           kh.hoaDons[findhd]=hd;
         }
      }
      setData(clone);
    }
    const editNote=(hd,note)=>{
      let clone=[...data];
      let findKh=clone.findIndex((kh)=>{ return kh.idKh.toString()==hd.idKh.toString()});
      if(findKh>-1){
         let kh=clone[findKh];
         let findhd=kh.hoaDons?.findIndex((hd)=>{return hd.maHd.toString()===hd.maHd.toString()});
         if(findhd>-1){
           kh.hoaDons[findhd].note=note;
         }
      }
      setData(clone);
    }
    const renderData=useMemo(()=>{
      return listKh.map((kh)=>{
          let key=JSON.stringify(kh);
         return <div  key={key} className=" rounded" style={{border:"1px solid black"}}>
           <KhachHang data={kh}></KhachHang>
         </div>
      })
  //     return data.map((kh)=>{
  //       return    <div  className='' >
  //       <div className=' text-center font-bold py-2'><i className=' capitalize'>{kh.tenKh}</i></div>
  //       <div>
  //            {
  //              kh.hoaDons.map((hd)=>{
  //               let key=JSON.stringify(hd);
  //               return  <ItemHd key={key} editThanhToan={editThanhToan} editNote={(note)=>{editNote(hd,note)}} hd={hd}></ItemHd>
  //              })
  //            }
  //       </div>
  //  </div>
  //     })
    },[listKh])
  return (
    <div>
       <Header isBack={true} title={"Danh Sách Công Nợ"}></Header>
        <div className=' space-y-3'>
            {renderData}
        </div>
    </div>
  )
}

export default PageCongNo