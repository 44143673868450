import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react'
import {  useNavigate } from 'react-router-dom';
function Header({isBack,title}) {
  const navigate=useNavigate();
  const goBack = () => {
      navigate(-1);
  };
  return (
    <div className=' bg-slate-100 rounded-md flex text-xl items-center p-2'>
      {isBack&&<div className=' flex items-center w-2/10'>
     <ArrowLeftOutlined onClick={()=>{goBack()}} />
     </div>}
      <div className='w-10/12 text-center mx-auto'>
      <p className=' text-lg'>{title}</p>
      </div>
    </div>
  )
}

export default Header