import React, { useState } from 'react'
import { Card, Popconfirm, Spin, Switch, Tag } from 'antd';
import moment from 'moment';
import { checkRes, formatCurrency } from '../../../unity';
import EditNote from '../../../v1/pages/home/listUser/hoaDon/EditNote';
import { api_2024 } from '../../services/apiService';

function ItemHd({ hd, editThanhToan, editNote }) {
  const [loading, setLoading] = useState(false);
  let thanhToan = () => {
    setLoading(true);
    api_2024.put({ nameSheet: "hoaDon", id: hd.maHd, data: { thanhToan: hd.thanhToan == "x" ? "" : "x" } }).then(
      (res) => {
        let value = checkRes(res);
        if (value) {
          editThanhToan({ ...hd, thanhToan: hd.thanhToan == "x" ? "" : "x" });
          setLoading(false);
        } else {
          alert("that bai")
        }
      }
    )
  }
  let putNote = (note) => {
    setLoading(true);
    api_2024.put({ nameSheet: "hoaDon", id: hd.maHd, data: { note } }).then(
      (res) => {
        let value = checkRes(res);
        if (value) {
          setLoading(false);
          editNote(hd, note);
        } else {
          alert("that bai")
        }
      }
    )
  }
  return (
    <Spin spinning={loading}>
      <Card

        title={moment(hd.day).format("DD/MM/YY")}
        extra={
          <>
            <Popconfirm
              title="Đổi trạng thái thanh toán"
              description={<div>
                Bạn có chắc đổi hoá đơn thành {hd.thanhToan == "x" ? <span className='text-blue-500'>đã</span> : <span className='text-red-500'>chưa</span>} thanh toán
              </div>}
              onConfirm={thanhToan}
              okText={<b className='  text-blue-500 text-lg'>ok</b>}
            >
              <Switch className=' bg-slate-200' checked={hd.thanhToan === "x" ? false : true} />
            </Popconfirm>
            <Tag className=' text-lg' color={hd.thanhToan == "x" ? "red" : "success"}>{hd.thanhToan == "x" ? 'Chưa thanh toán' : 'Đã thanh toán'}</Tag>
          </>
        }
        style={{
          width: '100%',
          height: `100%`,
          border: "1px solid black"
        }}
      >
        <EditNote editNote={putNote} note={hd.note}></EditNote>
        {
          hd.chiTietHoaDons.map((ct, i) => {
            return <div>
              <ul>
                <li className=''>
                  <div> <i className=' font-bold text-lg'>{i + 1}.</i> {ct.tenSp} - NPP: <span className=' underline'>{formatCurrency(ct.giaSp)}</span></div>
                  <div className=' text-center shadow-sm flex justify-between px-4'> <i>số lượng: {ct.sl}</i> | <i className=' text-blue-500'>thành tiền: {formatCurrency(ct.thanhTien)}</i></div>
                </li>
              </ul>
            </div>
          })
        }
        <div className=' flex justify-between p-5'>
          <p className=' text-lg '>Tổng:</p>
          <p className=' text-blue-500 font-bold  text-lg ' >{(() => {
            let t = 0;
            hd.chiTietHoaDons.forEach((item) => {
              t += item.thanhTien * 1;
            })
            return formatCurrency(t);
          })()}</p>
        </div>
      </Card>
    </Spin>
  )
}

export default ItemHd