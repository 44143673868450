import { Badge, Card, Popconfirm, Switch, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router'
import { checkRes, formatCurrency } from '../../../unity';
import EditNote from '../../../v1/pages/home/listUser/hoaDon/EditNote';
import { api_2024 } from '../../../v1/services/test';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import { updateHdOfKh, updateKhachHang } from '../../redux/khachHangSlice';
import ItemHd from './ItemHd';
import ThanhToan from './ThanhToan';

function HoaDonsPage() {
    const {id}=useParams();
    const [data,setData]=useState([]);
    const [kh,setKh]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [open, setOpen] = useState(false);
    const dispatch=useDispatch();
    const onClose = () => {
      setOpen(false);
    };

    useEffect(()=>{
        setIsLoading(true);
        api_2024.get("khachHang",{where:{idKh:id},include:[{as:"hoaDons",include:[{as:"chiTietHoaDons"}]}]}).then((res)=>{
            let value=checkRes(res);
            if(value){
                setData(value.hoaDons);
                let {hoaDons,...rest}=value;
                setKh(rest);
                setIsLoading(false);
            }else{
                console.log(res);
                alert(res.data.err);
            }
        })
    },[id])
    const tabList = [
       
        {
          key: 'tab1',
          tab:<Badge color={'hsl(102, 53%, 61%)'} offset={[15, 0]} count={data.length}>
          <p>Hoá đơn </p>
        </Badge> ,
        },
        {
            key: 'tab2',
            tab: <Badge offset={[15, 0]} count={(()=>{
                let count=0;
                data.forEach((hd)=>{
                   if(hd.thanhToan=="x"){
                    count+=1;
                   }
                })
                return count;
            })()}>
              <p>Công nợ </p>
            </Badge>,
          },
        
      ];
      
      const [activeTabKey1, setActiveTabKey1] = useState('tab1');
      const onTab1Change = (key) => {
        setActiveTabKey1(key);
      };
      let editThanhToan = (hd) => {
        console.log('test',hd);
       for(let item of data){
        if(item.maHd.toString()==hd.maHd.toString()){
          item.thanhToan=hd.thanhToan;
          dispatch(updateHdOfKh({idKh:kh.idKh,hd:{...item}}));
          break;
        }

       }
             setData([...data]);
    }
    let editNote=(hd,note)=>{
        data.find((item)=>{
            if(item.maHd.toString()==hd.maHd.toString()){
                item.note=note;
                return true
            }
            return false
             })
             setData([...data]);
       
    }
    let editDataThanhToan= (listHds)=>{
      let clone=[...data];
      listHds.forEach((maHd)=>{
            let index=clone.findIndex((hd)=>{return hd.maHd.toString()===maHd.toString()})
            if(index>=0){
                clone[index]={...clone[index],thanhToan:clone[index].thanhToan==="x"?"":"x"};
                dispatch(updateHdOfKh({idKh:kh.idKh,hd:clone[index]}));
            }
            
      })
      setData(clone);
    }
      const contentList = {
        tab1: <div className=''>
                 <div className=' '>
            <Tag className=' text-lg my-3' color="blue">HĐ đã mua: </Tag>
             <p className=' text-sm font-semibold space-y-2 '>{
                 data.map((hd)=>{
                    return<ItemHd key={hd.maHd} hd={hd} editNote={editNote} editThanhToan={editThanhToan}></ItemHd>
                })
            }</p>
          </div>
        </div>,
        tab2:  <div className=' '>
        <div className=' flex justify-between'>
        <Tag className=' text-lg my-3' color="red">HĐ chưa thanh toán: </Tag>
        <Tag onClick={()=>{setOpen(true)}} className=' text-lg my-3 shadow-md' color="blue">Thanh toán các HĐ</Tag>
          </div> 
        <h1 className=' p-3'>Tổng nợ: {(()=>{
          let t=0;
          data.forEach((item)=>{
            if( item.thanhToan=="x"){
                item.chiTietHoaDons.forEach((ct)=>{
                  t+=ct.thanhTien*1;
                })
            }
           
          })
          return formatCurrency(t);
        })()}</h1>
        <div className=' text-sm font-semibold '>{
              data.filter((hd,i)=>{
               return hd.thanhToan=="x";
            }).map((hd)=>{
                return <ItemHd key={hd.maHd} hd={hd} editNote={editNote} editThanhToan={editThanhToan}></ItemHd>
            })
        }</div>
      </div> 
         
      };

  return (
   <div>
      {isLoading &&  <Loading></Loading> }
      <ThanhToan editDataThanhToan={editDataThanhToan} onClose={onClose} open={open} idKh={kh.idKh} data={data.filter((item)=>{return item.thanhToan=="x"})}></ThanhToan>
      <Header isBack={true} title={"Công Nợ"}></Header>
      <Card
      style={{
        width: '100%',
        fontSize: '20px',
      }}
      title={kh.tenKh + " / " +kh.sdtKh}
      extra={<Tag className=' text-lg' color={kh.aro=="x"?"success":"red"}>{kh.aro=="x"?"khách ARO":"không ARO"}</Tag>}
      tabList={tabList}
      activeTabKey={activeTabKey1}
      onTabChange={onTab1Change}
    >
      {contentList[activeTabKey1]}
    </Card>
   </div>
  )
}

export default HoaDonsPage