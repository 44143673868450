import { Card, Popconfirm, Spin, Switch, Tag } from 'antd';
import Meta from 'antd/es/card/Meta';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { checkRes, formatCurrency } from '../../../../unity';
import { api_2024 } from '../../../../v1/services/test';
import { updateKhachHang } from '../../../redux/khachHangSlice';

function KhachHang({ data }) {
  const dispatch = useDispatch();
 const [loading,setLoading]=useState(false);
  const [hd,setHd]=useState({tongChiTieu:0,chuaThanhToan:0})
  const navigate=useNavigate();
  const tabList = [
    {
      key: 'tab1',
      tab: 'Thông tin',
    },
    {
      key: 'tab2',
      tab: <p>Hoá đơn </p>,
    },
    {
      key: 'tab3',
      tab: 'Thanh toán',
    },
  ];
  
  const [activeTabKey1, setActiveTabKey1] = useState('tab1');
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  let changeAro = () => {
    setLoading(true);
    api_2024.put({ nameSheet: "khachHang", id: data.idKh, data: { aro: data.aro === "x" ? "" : "x" } }).then(
      (res) => {
        let value = checkRes(res);
        if (value) {
          setLoading(false);
          dispatch(updateKhachHang({ ...data, aro: data.aro === "x" ? "" : "x" }));
        } else {
          alert(res);
        }
      }
    )
  }
  useEffect(()=>{
    let t = 0;
    let np=0;
    data.hoaDons?.forEach((item) => {
      if (!(item.thanhToan === "x")) {
        item.chiTietHoaDons.forEach((ct) => {
          t += ct.thanhTien * 1;
        })
      }else{
         np+=1;
      }
    })
    setHd({chuaThanhToan:np,tongChiTieu:t})
  },[])
 
  const contentList = {
    tab1: <div className=' p-1  flex-col items-center '>
      <b className=' text-black '>{data.aro == "x" ? <Tag color="success">Khách ARO</Tag> : <Tag color="error">Không ARO</Tag>}</b>

      <Popconfirm
        title="Đổi trạng thái ARO"
        description={<div>
          <p> Bạn có chắc đổi {data.tenKh} thành {data.aro == "x" ? <span className='text-red-500'>không</span> : <span className='text-blue-500'>có</span>} ARO</p>

        </div>}
        onConfirm={changeAro}
        okText={<b className='  text-blue-500 text-lg'>ok</b>}
      >
        <Switch style={{ background: data.aro === "x" ? "blue" : "silver" }} checked={data.aro === "x" ? true : false}
        />
      </Popconfirm>
      <div className=' flex items-center my-1 '>
        <Tag color="gold">Phone</Tag> <p className=' text-sm font-semibold'>{data.sdtKh}</p>
      </div>
      <div className='flex mt-3'>
        <Tag color="blue">Tổng chi: </Tag> <p className=' text-sm font-semibold underline'>{formatCurrency(hd.tongChiTieu)}</p>
      </div>
    </div>,
    tab2: <div className=''>
             <div className='flex mt-3 items-center space-x-3'>
        <Tag color="blue">HĐ đã mua: </Tag> <p className=' text-sm font-semibold '>{data.hoaDons.length}</p><a className='text-sm text-blue-500 underline' onClick={()=>{navigate(`hoadons/${data.idKh}`)}}>More</a>
      </div>
      <div className='flex mt-3 items-center space-x-3'>
        <Tag color="red">HĐ chưa thanh toán: </Tag> <p className=' text-sm font-semibold '>{hd.chuaThanhToan}</p>
      </div>
    </div>,
    tab3:<div className=' h-[10vh] overflow-scroll'>
         <ul>
         {data.bienDongs.map((item)=>{
          let {idBd,idKh,day,type,money,soDu,maHds}=item;
               return <li>
                   {moment(day).format("DD/MM")}-{type}-{formatCurrency(money)}
               </li>
          })}
         </ul>
    </div>
  };
  return (
    <Card
    style={{
      width:'100%',
     height:'100%',
      fontSize: '20px',
    }}
    title={<><p>{data.tenKh}</p> {hd.chuaThanhToan>0?<Tag color="red">có hđ chưa thanh toán</Tag>:<></>}</>}
    extra={<a className=' text-lg underline text-blue-600' onClick={()=>{navigate(`../hoadons/${data.idKh}`)}}>xem thêm</a>}
    tabList={tabList}
    activeTabKey={activeTabKey1}
    onTabChange={onTab1Change}
  >
    <Spin spinning={loading}>
    {contentList[activeTabKey1]}
    </Spin>
  </Card>
  );
}

export default KhachHang