let USER_INFOR = "USER_INFOR";
let login="login"
export let userInforLocal = {
  // set lưu xuống
  set: (userData) => {
    let json = JSON.stringify(userData);
    sessionStorage.setItem(USER_INFOR, json);
  },
  get: () => {
    let json = sessionStorage.getItem(USER_INFOR);

    if (json) {
      return JSON.parse(json);
    } else {
      return null;
    }
  },
  remove: () => {
    sessionStorage.removeItem(USER_INFOR);
  },
};
export let localLogin = {
  // set lưu xuống
  set: (userData) => {
    let json = JSON.stringify(userData);
    localStorage.setItem(login, json);
  },
  get: () => {
    let json = localStorage.getItem(login);

    if (json) {
      return JSON.parse(json);
    } else {
      return null;
    }
  },
  remove: () => {
    localStorage.removeItem(USER_INFOR);
  },
};
