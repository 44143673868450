import axios from "axios";
let uri="https://script.google.com/macros/s/AKfycbz6pSVTt9NdZh44TMkBVVEMi8F_GibQHuDJ4q6FQHuImMzrhg9jzuGrQVfcaavoomvO/exec?action="
let configUrl=(stringAction ,obj)=>{
   return uri+`${stringAction}&`+`${obj?"obj="+JSON.stringify(obj):""}`;
}
export let api_2024={
   get:(action,obj=null)=>{
      let url=configUrl(action,obj); 
      return axios.get(url);
   },
   post:({nameSheet,data,include})=>{
      let url=uri+'post'; 
      return axios.post(url,JSON.stringify({nameSheet,data,include}))
   },
   put:({id,nameSheet,data})=>{
      let url=uri+'put'; 
      return axios.post(url,JSON.stringify({id,nameSheet,data}))
   },
}
export let auth={
   login:({data,nameSheet="user"})=>{
      let url="https://script.google.com/macros/s/AKfycbxu5l5ufp0BtvfqN5uwvDRVSqch4R8R1dFjkH4dbqN0fLnIrbughqPdExpolj_21Le6MA/exec?action="+'postLogin'; 
      return axios.post(url,JSON.stringify({nameSheet,data}))
   },
   testGetToken:()=>{
      let config = {
         method: 'get',
         maxBodyLength: Infinity,
         url: 'https://script.google.com/macros/s/AKfycbxu5l5ufp0BtvfqN5uwvDRVSqch4R8R1dFjkH4dbqN0fLnIrbughqPdExpolj_21Le6MA/exec?action=postLogin',
         headers: { 
           'Authorization': '""'
         },
       };
       
       axios.request(config)
   }
}