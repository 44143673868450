import React, { useEffect } from 'react'
import { userInforLocal } from '../services/local.service';

function Secureview({children}) {
    useEffect(()=>{
        let user=userInforLocal.get();
        if(user==null || user?.role !=='admin'){
         window.location.href='/login';
         userInforLocal.remove()
        }
    })
  return children
}

export default Secureview