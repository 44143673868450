export let checkRes=(res)=>{
    let {value,err}=res.data;
    if(err){return false}
    return value;
}
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
};

export const removeVietnameseDiacritics=(str) =>{
    const diacriticsMap = {
      'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
      'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
      'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
      'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
      'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
      'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
      'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
      'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
      'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
      'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
      'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
      'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
      'đ': 'd','Đ':'D'
    };
  
    return str.replace(/[àáảãạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵđĐ]/g, match => diacriticsMap[match] || match);
  };
  export  function thanhTien(aro,price,sl){
    let list=sl.toString().split("/");
   if(list.length<2)
  {
   return aro?(price*sl)-(price*sl*10)/100:(price*sl)-(price*sl*5)/100;
  }else{
   return  aro?(price*(list[0]/list[1]))-(price*(list[0]/list[1])*10)/100:(price*(list[0]/list[1]))-(price*(list[0]/list[1])*5)/100;
  }
}
export const mapDataIndex=(obj,list=[])=>{
  if(Array.isArray(list)){
      return obj[list];
  }
  let mapObj=null;
  // return obj["hocSinh"]["name"];
  let [fr,...rest]=list;
     if(obj[fr]){
       mapObj=obj[fr];
        if(rest.length>0){
         return mapDataIndex(mapObj,rest);
        }else{
          return mapObj;
        }
     }
}