import { Button, Card, Checkbox, Drawer, Form, Input, Popover, Select, Space } from 'antd';
import moment from 'moment/moment';
import React, { useState } from 'react'
import { checkRes, formatCurrency } from '../../../unity';
import { apiMaster } from '../../../v1/services/api.service';

function ThanhToan({idKh,data,open,onClose,editDataThanhToan}) {
   
    const [loading, setLoading] = useState(false); 
    const [totalSelect,setTotalSelect]=useState(0);
  
      const { Option } = Select;
      const onFinish = (values) => {
         if(values.maHds.length>0){
          setLoading(true);
          // console.log('Received values of form: ', values);
          const dataPost={
            idKh,
            "day": new Date(),
            "type": values.type,
            "money": values.money.toString().replace(/[,.]/g, ''),
            "soDu": 0,
            "maHds": values.maHds.map((item)=>{return item.maHd;}),
        }
        // console.log(dataPost);
         apiMaster.thanhToan(dataPost).then((res)=>{
             let value=checkRes(res);
             if(value){
               setLoading(false);
              setTotalSelect(0);
              editDataThanhToan(dataPost.maHds);
             }
         })
         }else{
          alert("Hãy chọn những hoá đơn để thanh toán");
         }
      };
      const formItemLayout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 14,
        },
      };   
      const validatorMoney=(_, value) => {
        let clone=value.toString().replace(/[,.]/g,'');
        if(!/^[0-9]+$/.test(clone)){return Promise.reject(new Error(`Số tiền phải là số`));}
        if (clone*1 < totalSelect) {
          return Promise.reject(new Error(`Số tiền phải lớn hơn hoặc bằng ${formatCurrency(totalSelect)}`));
        }
        return Promise.resolve();
      }
  return (
    <Drawer
        
    title="Thanh toán các hoá đơn"
    placement={"bottom"}
   
    height={'70vh'}
    onClose={onClose}
    open={open}
    extra={
      <Space>
        <Button className=' text-blue-500 border-2 bg-blue-100 shadow-md' type="primary" onClick={onClose}>
          OK
        </Button>
      </Space>
    }
  >
  <div id='listHds'  className=' p-1'>
  <Card loading={loading} className='border-none'>
   <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
     
      style={{
        width: "100%",
        // maxWidth: 600,
      }}
      className="p-3"
    >
      <Form.Item  name="maHds" label="chọn hoá đơn để thanh toán">
        <Checkbox.Group className='' onChange={(e)=>{ 
          let t=0;
          e.forEach((hd)=>{ 
            hd.chiTietHoaDons.forEach((cthd)=>{
              t+=cthd.thanhTien*1
            })
          });
          setTotalSelect(t);
          }}>
          <div className=' flex flex-col'>
            {data.map((item) => {
              return <Checkbox 
                
                value={item}
                style={{
                  // lineHeight: '32px',
                  width: "100%",
                  // border:"1px solid black",
                }}
              >
                <Popover content={
                  <div className=' w-11/12'>
                    {item.chiTietHoaDons.map((cthd) => {
                      return <p className=' border-b-2 p-1 border-black'>{cthd.tenSp} | sl: {cthd.sl} | NPP: {formatCurrency(cthd.giaSp)} | thành Tiền: {formatCurrency(cthd.thanhTien)}</p>
                    })}
                  </div>
                } title={() => {
                  let t = 0;
                  item.chiTietHoaDons.forEach((cthd) => {
                    t += cthd.thanhTien * 1;
                  })
                  return formatCurrency(t) || moment(item.day).format("DD/MM");
                }}>
                  <Button > ngày: {moment(item.day).format("DD/MM/YY")},id: {item.maHd},tổng: {(() => {
                    let tt = 0;
                    item.chiTietHoaDons.forEach((cthd) => {
                      tt += cthd.thanhTien * 1;
                    })
                    return formatCurrency(tt);
                  })()}</Button>
                </Popover>
              </Checkbox>
            })}
          </div>
        </Checkbox.Group>
      </Form.Item>
   
      <div className=' flex items-center'>
      <Form.Item
     className=' w-7/12 '
      label={`nhập tiền: ${formatCurrency(totalSelect)}`}
     rules={[
       { required: true, message: 'Vui lòng nhập số tiền!' },
       { validator: validatorMoney }, // Sử dụng hàm validatorMoney
     ]} name='money'
     >
      <Input  className=' text-xl w-full' placeholder='nhập tiền' ></Input>
    </Form.Item>
     
      <Form.Item
      className='text-xl w-5/12'
        name="type"
        label="hình thức"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'chọn loại thanh toán',
          },
        ]}
       
      >
        <Select  placeholder="thanh toán">
          <Option  value="tiền măt">tiền mặt</Option>
          <Option value="chuyển khoản">chuyển khoản</Option>
        </Select>
      </Form.Item>
     </div>
     <div className=' text-center'>
     <Button className=' text-xl border bg-blue-500 text-white ' type="primary" htmlType="submit">
      ok
    </Button>
     </div>
    </Form>
   </Card>
  </div>
  </Drawer>
  )
}

export default ThanhToan