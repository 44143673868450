import React, { useState } from 'react';
import { Input, Button, Checkbox, Spin } from 'antd'; // Import Spin component from antd for loading indicator
import { auth } from '../../services/apiService';
import { checkRes } from '../../../unity';
import { localLogin, userInforLocal } from '../../services/local.service';
import { useNavigate } from 'react-router';
import { apiMaster } from '../../services/api.service';
import { useEffect } from 'react';

function LoginPage() {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        let checkLogin = localLogin.get();
        if (checkLogin) {
          setLoading(true)
            apiMaster.login(checkLogin).then((res) => {
                let value = checkRes(res);
                if (value) {
                    userInforLocal.set(value);
                    navigate("/")
                } else {
                    console.log(res);
                    alert(res);
                }
            })
        }
    }, []);

    const handleLogin = () => {
        setLoading(true); // Set loading state to true when login starts
        apiMaster.login({ username: userId, password }).then((res) => {
            let value = checkRes(res);
            if (value) {
                userInforLocal.set(value);
                if (remember) {
                    localLogin.set({ username: userId, password });
                }
                navigate("/");
            } else {
                console.log(res);
                alert(res);
            }
        }).finally(() => {
            setLoading(false); // Set loading state to false when login finishes (whether success or failure)
        });
    };

    return (
       <Spin spinning={loading}> <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="max-w-md w-full p-6 space-y-6 bg-white shadow-md rounded-md">
                <h2 className="text-2xl font-bold text-center">Đăng nhập</h2>
                <div>
                    <label htmlFor="userId" className="block text-sm font-medium text-gray-700">
                        User ID
                    </label>
                    <Input
                        id="userId"
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                    </label>
                    <Input.Password
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <Checkbox
                        checked={remember}
                        onChange={(e) => setRemember(e.target.checked)}
                    >
                        Ghi nhớ đăng nhập
                    </Checkbox>
                    <a href="#" className="text-sm text-blue-500 hover:underline">
                        Quên mật khẩu?
                    </a>
                </div>
                <Button className=' bg-blue-500' type="primary" onClick={handleLogin} block>
                    Đăng nhập
                </Button>
                
            </div>
        </div>
        </Spin>
    );
};

export default LoginPage;
