import { Calendar, DatePicker, Drawer, Tag, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { checkRes, formatCurrency, thanhTien } from '../../../unity';
import ProductList from './ProductList';
import dayjs from 'dayjs';
import moment from 'moment';
import { api_2024 } from '../../services/apiService';
import EditNote from '../EditNote';
function DrawerNhapHoaDon({ handleReload, isOpen, onClose, dataUser }) {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({ aro: "x" });
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false); // Thêm biến trạng thái này
    const [hoaDon, setHoaDon] = useState({
        day: new Date().toISOString().slice(0, 10), // Lấy ngày hiện tại
        idKh: dataUser?.idKh,
        note: "",
        thanhToan: "",
        chiTietHoaDons: [],
    });
    const { RangePicker } = DatePicker;

    const closeSubmit = () => {
        setIsSubmit(false);
    }
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    useEffect(() => {
        if (dataUser) {
            setUser(dataUser);
        }
    }, [user]);
    const handleSelectProduct = (product) => {
        const sl = 1;
        const tt = thanhTien(user.aro, product.giaSp, sl);
        const productWithQuantity = { ...product, sl, thanhTien: tt };
        setHoaDon({ ...hoaDon, chiTietHoaDons: [...hoaDon.chiTietHoaDons, productWithQuantity] });
        // dispatch(addToChiTietHoaDons(productWithQuantity));
    };
    const submitHD = () => {
        setSubmitting(true);

        closeSubmit();

        if (dataUser) {
            // apiMaster.postHd(hoaDon).then((res) => {
            //     let value = checkRes(res);
            //     if (value) {
            //         onClose();
            //         handleReload();
            //         setSubmitting(false);
            //     }
            // })
           
            api_2024.post({nameSheet:"hoaDon", data: hoaDon, include: [{ as: "chiTietHoaDons" }] }).then(
                (res) => {
                    console.log(res);
                    let value = checkRes(res);
                    if (value) {
                        onClose();
                        handleReload();
                        setSubmitting(false);
                    }else{
                        alert("nhắn tin con nha tư <3");
                    }
                }
            )
        } else {
            //
            //  apiMaster.postKh(user).then((res)=>{
            //     let kh=checkRes(res);
            //     if(kh){
            //         apiMaster.postHd({...hoaDon,idKh:kh.idKh}).then((res)=>{
            //             let value=checkRes(res);
            //             if(value){
            //                 onClose();
            //                 handleReload();
            //                 setSubmitting(false);
            //             }
            //            })
            //     }
            //  })
            //

            // testApi.postKh({ data: { ...user, hoaDons: [hoaDon] }, include: [{ as: "hoaDons", include: [{ as: "chiTietHoaDons" }] }] }).then(
            //     (res) => {
            //         console.log(res);
            //         let value = checkRes(res);
            //         if (value) {
            //             onClose();
            //             handleReload();
            //             setSubmitting(false);
            //         }
            //     }
            // )

            //
            api_2024.post({
                data:{ ...user, hoaDons: [hoaDon] },nameSheet:"khachHang",include: [{ as: "hoaDons", include: [{ as: "chiTietHoaDons" }] }]})
            .then( (res) => {
                console.log(res);
                let value = checkRes(res);
                if (value) {
                    onClose();
                    handleReload();
                    setSubmitting(false);
                }else{
                    alert("nhắn tin con nha tư <3");
                }
            })
        }
    }
    const handleQuantityChange = (productId, newQuantity) => {

        const index = hoaDon.chiTietHoaDons.findIndex((item) => item.maSp === productId);
        if (index >= 0) {
            let cloneHd = { ...hoaDon };
            let product = hoaDon.chiTietHoaDons[index];
            const tt = thanhTien(user.aro, product.giaSp, newQuantity);
            product.sl = newQuantity;
            product.thanhTien = tt;

            cloneHd.chiTietHoaDons[index] = product;

            setHoaDon(cloneHd);
        }
    };
    const handleRemoveFromInvoice = (sp) => {
        const cloneHd = { ...hoaDon };
        cloneHd.chiTietHoaDons = cloneHd.chiTietHoaDons.filter((item) => { return item.maSp != sp.maSp });
        setHoaDon(cloneHd)
    }
    const editNote = (value) => {
        const cloneHd = { ...hoaDon };
        cloneHd.note = value;
        setHoaDon(cloneHd);
    }
    const totalPrice = useCallback(() => {
        return hoaDon.chiTietHoaDons?.reduce((total, item) => {
            return total + item.thanhTien;
        }, 0);
    }, [hoaDon]);


    //   console.log(user);
    return (
        isOpen && <div className='w-full h-full z-40  relative top-0 left-0'>
            <Drawer
                zIndex={'999'}
                title="Nhập Hoá Đơn"
                placement={"bottom"}
                closable={true}
                onClose={onClose}
                open={open}
                key={"bottom"}
                height={'90%'}
            >

                <div className='w-full h-full bg-blue-50  overflow-scroll p-3'>
                    {
                        dataUser && <div id='inforUser' className=' flex  space-x-3 text-lg'>

                            <p>{user.tenKh} </p>  <p>{user.sdtKh}</p> <p>{user.aro == "x" ? <Tag color="success">Khách ARO</Tag> : <Tag color="error">Không ARO</Tag>} </p>
                        </div>
                    }
                    {
                        !dataUser && <div className=' space-y-3'>
                            <input onChange={(e) => {
                                setUser({ ...user, tenKh: e.target.value })
                            }} className='w-full text-lg border p-2' placeholder='nhập tên kh mới'></input>
                            <input onChange={(e) => {
                                setUser({ ...user, sdtKh: e.target.value })
                            }} className='w-full text-lg border p-2' placeholder='nhập sdt kh mới'></input>
                            <div className=' flex space-x-4 items-center'>
                                <p>ARO: </p> <input onChange={(e) => {
                                    setUser({ ...user, aro: e.target.checked ? "x" : "" })
                                    setHoaDon({ ...hoaDon, chiTietHoaDons: [] })
                                }}
                                    checked={user.aro == "x" ? true : false} className=' w-5 h-5' type={"checkbox"}></input>
                            </div>
                        </div>
                    }
                    <ProductList handleSelectProduct={handleSelectProduct}></ProductList>
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold mb-2">Danh sách chi tiết hóa đơn:</h3>
                        <ul>
                            {hoaDon.chiTietHoaDons.map((item) => (
                                <li key={item.id} className=" border-b last:border-b-0 border-black shadow-lg p-2">
                                    <div className="flex items-center justify-between">
                                        <div className="flex-grow">
                                            <h3 className="text-lg font-semibold mb-1">{item.tenSp}
                                                <span className="text-gray-600">| NPP: {formatCurrency(item.giaSp)} </span>
                                            </h3>

                                            <div className="flex text-lg items-center space-x-2">
                                                <label className="text-gray-600">Số lượng:</label>
                                                <input
                                                    type="text"
                                                    className="w-16 p-1 border rounded-md"
                                                    defaultValue={1}
                                                    onChange={(e) => {
                                                        handleQuantityChange(item.maSp, e.target.value)
                                                    }
                                                    }
                                                />
                                                <span className="text-gray-600">Thành tiền:</span>
                                                <p>{formatCurrency(item.thanhTien)}</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-2 py-1 bg-red-500 text-white rounded-md"
                                            onClick={() => {
                                                handleRemoveFromInvoice(item)
                                            }}
                                            type="button"
                                        >
                                            Xoá
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className=' text-center m-3 mb-16'>
                        <button onClick={() => {
                            setIsSubmit(true);
                        }} type='button' className=' px-2 py-1 rounded-md bg-blue-500 text-white font-bold text-lg'>Nhập Hoá Đơn</button>
                    </div>
                </div>
                {isSubmitting && <div className="fixed top-0 z-auto left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <p className="text-xl font-semibold mb-2">Đang nhập hoá đơn, vui lòng chờ...</p>
                        {/* Bạn có thể thêm thêm nội dung hoặc biểu tượng tại đây nếu cần */}
                    </div>
                </div>}
            </Drawer>
            {isSubmit &&
                <Drawer
                    title="xác Nhận Hoá Đơn"
                    placement={"right"}
                    closable={true}
                    onClose={closeSubmit}
                    open={isSubmit}
                    key={"right"}
                    zIndex={'999'}
                    height={'95%'}
                >
                    <div className="p-4 mx-2 bg-white rounded-lg shadow-lg w-12/12 max-w-md text-lg overflow-scroll">
                        <h2 className="text-2xl font-semibold mb-4">Xác nhận hóa đơn</h2>
                        <p className="mb-2">Tổng giá trị hóa đơn: {formatCurrency(Math.round(totalPrice()))} </p>
                        <DatePicker autoFocus={false} size="large" className=' text-lg ' onChange={(value) => {
                            setHoaDon({ ...hoaDon, day: moment(new Date(value)).format("DD/MM/YYYY") });
                        }} defaultValue={dayjs(moment(hoaDon.day).format("DD/MM/YYYY"), "DD/MM/YYYY")} format={"DD/MM/YYYY"} />
                        <p className="mb-2 capitalize">Khách hàng: {user ? user.tenKh : ''}</p>
                        <EditNote edit={true} onChange={editNote} editNote={editNote} note={hoaDon.note} ></EditNote>
                        <p>Trạng thái: <input className=' w-5 h-5 mt-3' onChange={(e) => { setHoaDon({ ...hoaDon, thanhToan: e.target.checked ? "" : "x" }) }} checked={hoaDon.thanhToan == "" ? true : false} type={"checkbox"}></input> {hoaDon.thanhToan == "" ? 'Đã thanh toán' : 'Chưa thanh toán'}</p>

                        {hoaDon.chiTietHoaDons.length > 0 && (
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold mb-2">Danh sách chi tiết hóa đơn:</h3>
                                <ul>
                                    {hoaDon.chiTietHoaDons.map((item) => (
                                        <li key={item.id} className="py-2 border-b last:border-b-0">
                                            <div className="flex items-center justify-between">
                                                <div className="flex-grow">
                                                    <h3 className="text-lg font-semibold mb-1">{item.tenSp}</h3>
                                                    <p className="text-gray-600">Giá: {formatCurrency(item.giaSp)} </p>
                                                    <div className="flex items-center space-x-2">
                                                        <label className="text-gray-600">Số lượng:</label>
                                                        <input
                                                            type="text"
                                                            className="w-16 p-1 border rounded-md"
                                                            value={item.sl}
                                                            onChange={(e) =>
                                                                handleQuantityChange(item.maSp, e.target.value)
                                                            }
                                                        />
                                                        <span className="text-gray-600">Thành tiền:</span>
                                                        <p>{formatCurrency(item.thanhTien)}</p>
                                                    </div>
                                                </div>
                                                <button
                                                    className="px-2 py-1 bg-red-500 text-white rounded-md"
                                                    onClick={() => handleRemoveFromInvoice(item)}
                                                    type="button"
                                                >
                                                    Xoá
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <div className=' text-center m-3 mb-20'>
                            <button onClick={() => {
                                submitHD();
                            }} type='button' className=' px-2 py-1 rounded-md bg-blue-500 text-white font-bold text-lg'>Xác Nhận</button>
                        </div>
                    </div>
                </Drawer>
            }
        </div>
    )
}

export default DrawerNhapHoaDon