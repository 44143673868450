import axios from "axios";
let uri="https://script.google.com/macros/s/AKfycbxXL8kgkq7MeB1SS9xrfUjT5NKm1RfBoKAm6G2zGweKmWvIzGsKHayrxgnrtTRo0VIFSQ/exec?action="
let configUrl=(stringAction ,obj)=>{
   return uri+`${stringAction}&`+`${obj?"obj="+JSON.stringify(obj):""}`;
}
export let apiMaster={
    login:({username,password})=>{
         let url=uri+"login";
         return axios.post(url,JSON.stringify({username,password}))
    },
     getKh:(obj=null)=>{
        let url=configUrl("kh",obj);
        return axios.get(url);
     },
     getNo:(obj=null)=>{
       let url=configUrl("No",obj);
       return axios.get(url);
     },
     getHd:(obj=null)=>{
      let url=configUrl("hd",obj);
      return axios.get(url);
     },
     getCthd:(obj=null)=>{
      let url=configUrl("cthd",obj);
      return axios.get(url);
     },
     getBd:(obj=null)=>{
      let url=configUrl("bd",obj);
      return axios.get(url);
     },
     getSp:(obj=null)=>{
      let url=configUrl("sp",obj);
      return axios.get(url);
     },
     postKh:(kh)=>{
        let url=uri+'kh';
        return axios.post(url,JSON.stringify(kh));
     },
     postHd:(obj)=>{
      let url=uri+'hd';
      return axios.post(url,JSON.stringify(obj));
     },
     postBd:(obj)=>{
      let url=uri+"bd";
      return axios.post(url,JSON.stringify(obj));
     },
     deleteHd:(obj)=>{
      let url=uri+'deleteHd';
      return axios.post(url,JSON.stringify(obj));
     },
     deleteKh:(obj)=>{
      let url=uri+"deleteKh";
      return axios.post(url,JSON.stringify(obj))
     },
     thanhToan:(obj)=>{
      let url=uri+"thanhToan";
      return axios.post(url,JSON.stringify(obj))
     }
}