import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const BirthdatePicker = ({ form }) => {
  
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const days = selectedMonth && selectedYear
    ? Array.from({ length: daysInMonth(selectedMonth, selectedYear) }, (_, i) => i + 1)
    : Array.from({ length: 31 }, (_, i) => i + 1);

 // Khi form thay đổi, reset lại giá trị của BirthdatePicker
 useEffect(() => {
  const birthday = form.getFieldValue('birthday');
  if (birthday) {
    const [year, month, day] = birthday.split('-');
    setSelectedDay(parseInt(day));
    setSelectedMonth(parseInt(month));
    setSelectedYear(parseInt(year));
  } else {
    setSelectedDay(null);
    setSelectedMonth(null);
    setSelectedYear(null);
  }
}, [form]);

  useEffect(() => {
    // Cập nhật giá trị ngày sinh trong form khi chọn đủ ngày, tháng, năm
    if (selectedDay && selectedMonth && selectedYear) {
      form.setFieldsValue({
        birthday: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      });
    }
  }, [selectedDay, selectedMonth, selectedYear, form]);

  return (
    <Form.Item label="Ngày sinh" name="birthday" rules={[{ required: true, message: 'Chọn ngày sinh!' }]}>
      <div  style={{ display: 'flex', gap: '10px' }}>
        <Select
        className=' overflow-scroll'
          placeholder="Ngày"
          style={{ width: 100 }}
          value={selectedDay}
          onChange={setSelectedDay}
        >
          {days.map((day) => (
            <Option key={day} value={day}>
              {day}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Tháng"
          style={{ width: 150 }}
          value={selectedMonth}
          onChange={setSelectedMonth}
        >
          {months.map((month) => (
            <Option key={month} value={month}>
              Tháng {month}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Năm"
          style={{ width: 100 }}
          value={selectedYear}
          onChange={setSelectedYear}
        >
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
    </Form.Item>
  );
};

export default BirthdatePicker;
