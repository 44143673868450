const { createSlice } = require("@reduxjs/toolkit");

let initialState={
    hoaDons:[],
}
 let hoaDonSlice=createSlice({
    name:"hoaDonSlice",
    initialState,
    reducers:{
        setHoaDons:(state,{payload})=>{
            state.hoaDons=payload;
        },
      
        updateHd:(state,{payload})=>{
            console.log("checkupdatehd");
            let clone=[...state.hoaDons];
            let {maHd}=payload;
            let indexHd=clone.findIndex((item)=>{return item.maHd?.toString()===maHd.toString()});
             if(indexHd>-1){
                clone[indexHd]=payload;
             }
            state.hoaDons=clone;
            
        },
        addHd:(state,{payload})=>{
            state.hoaDons=[...state.hoaDons,payload];
        },

    }
 })
 export let {setHoaDons,updateHd,addHd}=hoaDonSlice.actions;
 export default hoaDonSlice.reducer;