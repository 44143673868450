import React from 'react'
import { Flex, Spin } from 'antd';
function Loading() {
  return (
  <div className='fixed top-0 left-0 bg-black w-screen h-screen  z-50 flex justify-center items-center '>
        <Spin className='' size='large'></Spin>
  </div>
  )
}

export default Loading