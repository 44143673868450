import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { checkRes } from '../../../../unity';
import { setHoaDons, updateHd } from '../../../redux/hoaDonSlice';
import { updateHdOfKh } from '../../../redux/khachHangSlice';
import { api_2024 } from '../../../services/apiService';
import ItemHd from '../../DanhSachHoaDonsKh/ItemHd';

function ListHd() {
 const listHd=useSelector((state)=>{return state.hoaDonSlice.hoaDons})

 const dispatch=useDispatch();
 useEffect(()=>{
     api_2024.get("hoaDon",{include:[{as:"chiTietHoaDons"},{as:"khachHang"}]}).then(
        (res)=>{
                let value=checkRes(res);
                if(value){
                   dispatch(setHoaDons(value));
                }   else{
                    alert(res.data.error);
                }      
            }
     )
 },[]);
 let editNote=(hd,note)=>{
    listHd.find((item)=>{
        if(item.maHd.toString()==hd.maHd.toString()){
            item.note=note;
            return true
        }
        return false
         })
         dispatch(updateHdOfKh({idKh:hd.khachHang.idKh,hd}));
}
let editThanhToan = (hd) => {
         dispatch(updateHdOfKh({idKh:hd.khachHang.idKh,hd}));
         dispatch(updateHd(hd));
}
  const renderList=useMemo(()=>{
     return [...listHd].reverse().map((hd)=>{
       if(hd.maHd!="" && hd.maHd!=null){
        let key=JSON.stringify(hd);
        return    <div key={key} className='flex-none  mx-1'style={{width:"90vw"}} >
        <div className=' text-center font-bold py-2'><i className=' capitalize'>{hd.khachHang?.tenKh}</i></div>
        <ItemHd editThanhToan={editThanhToan} editNote={(note)=>{editNote(hd,note)}} hd={hd}></ItemHd>
   </div>
       }
     })
  },[listHd])
  return (
    <div>
        <h1 className=' text-2xl font-blod p-3 '>Danh sách các hoá đơn...</h1>
        <div className=' flex h-full overflow-scroll'>
                {renderList}
        </div>
    </div>
  )
}

export default ListHd