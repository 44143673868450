import PageCongNo from "../pages/DanhSachCongNo/PageCongNo";
import HoaDonsPage from "../pages/DanhSachHoaDonsKh/HoaDonsPage";
import DataNuskin from "../pages/home/DataNuskin";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/LoginPage/LoginPage";
import SpaPage from "../pages/SpaPage/SpaPage";

export const routes=[
    {
        path:'/',
        component:<HomePage/>,
    },
    {
        path:'/hoadons/:id',
        component:<HoaDonsPage/>,
    },
    {
        path:'/congNo',
        component:<PageCongNo/>,
    },
    {
        path:'/login',
        component:<LoginPage/>,
    },
    {
        path:'/spa',
        component:<SpaPage/>,
    },
    {
        path:'/data',
        component:<DataNuskin/>,
    }
]