import { Tag } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkRes } from '../../../../unity';
import DrawerNhapHoaDon from '../../../components/nhapHoaDon/DrawerNhapHoaDon';

import { addHd, setHoaDons } from '../../../redux/hoaDonSlice';
import { setKhachHang } from '../../../redux/khachHangSlice';
import { api_2024 } from '../../../services/apiService';
import KhachHang from './KhachHang';
const diacritics = require('diacritics');
function ListKhachHang() {
  const [keySearch,setKeySearch]=useState("");
  const [isNhapHd,setNhapHoaDon]=useState(false);
  const [userDrawger,setUserDrawger]=useState(null);
  let dispatch=useDispatch();
    const data = useSelector((state) => {
        return state.khachHangSlice.khachHang;
    })
    const scrollRef = useRef(null);
    
    let filteredData = useMemo(() => {
      return  data.filter((item) =>
      diacritics.remove(item.tenKh.toLowerCase()).includes(diacritics.remove(keySearch.toLowerCase())) ||
      item.sdtKh.includes(keySearch.toLowerCase())
  );
    }, [data, keySearch]);
   
    const renderData = useMemo(() => {
      return data.map((kh,i) => {
        let key=JSON.stringify(kh);
        return (
          <div key={kh.idKh} className="flex-none mx-1 relative" style={{width:"90vw"}} ref={kh.idKh === filteredData[0]?.idKh ? scrollRef : null}>
            <KhachHang key={key} data={kh} />
            <Tag onClick={()=>{setUserDrawger(kh); setNhapHoaDon(true)}} className='  absolute bottom-0 right-0 flex-grow-1 shadow-lg'  color={'blue-inverse'}>
              <p>nhập Hoá Đơn</p>
              <p className=' text-center'>{kh.tenKh}</p>
              </Tag>
          </div>
        )
      });
    }, [data, filteredData]);
    useEffect(() => {
      // Scroll to the first matched customer when keySearch changes
      if (scrollRef.current) {
 
        // scrollRef.current.scrollLeft=0;
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest', // Set inline to start for left alignment
        });
      }
    }, [keySearch]);
   let onClose=()=>{
    setNhapHoaDon(false);
   }
   let reload=()=>{
    api_2024.get("khachHang",{include:[{as:"hoaDons",include:[{as:"chiTietHoaDons"}]},{as:"bienDongs"}]}).then(
      (res)=>{
        let value=checkRes(res);
        if(value){
           dispatch(setKhachHang(value));
        }else{
          alert(res.data.error)
        }
      }
    )
      api_2024.get("hoaDon",{include:[{as:"chiTietHoaDons"},{as:"khachHang"}]}).then((res)=>{
         let value=checkRes(res);
         if(value){
            dispatch(setHoaDons(value));
         }else{
           alert(res.data.error)
         }
      })
   }
  return (
    <div className=' '>
       {isNhapHd && <DrawerNhapHoaDon handleReload={reload} onClose={onClose} isOpen={isNhapHd} dataUser={userDrawger}></DrawerNhapHoaDon>}
   <div className=' flex justify-between  items-center'>
      <p className=' text-xl font-blod p-3 flex-shink-1'> Danh sách khách hàng</p>
      <Tag onClick={()=>{setUserDrawger(null); setNhapHoaDon(true)}} className='text-lg flex-grow-1 shadow-lg'  color={'blue'}>nhập hđ cho kh mới</Tag>
   </div>
    <input  onChange={(e)=>{setKeySearch(e.target.value)}} type="text" className=' w-full border rounded-lg text-lg px-4 py-2' placeholder='Tìm khách hàng theo tên hoặc sdt' />
    <div className='  flex overflow-scroll w-screen '>
      {renderData}
    </div>
    </div>
  )
}

export default ListKhachHang