import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { formatCurrency } from '../../../../unity';
import { useNavigate } from 'react-router';
function Total() {
  const navigate=useNavigate();
    const data = useSelector((state) => {
        return state.khachHangSlice.khachHang;
    })
    const [total, setTotal] = useState({
        slKh: 0,
        slHd: 0,
        money: 0,
        aro:0,
    });
    useEffect(() => {
        let t = {
            slKh: 0,
            slHd: 0,
            money: 0,
            aro:0,
        }
        data.forEach((kh) => {
            let filterHdNo = kh.hoaDons.filter((hd) => {
                if (hd.thanhToan == "x") {
                    t.slHd += 1;
                    hd.chiTietHoaDons.forEach((ct) => {
                        t.money += ct.thanhTien * 1;
                    })
                    return true;
                }
                return false;
            })
            if (filterHdNo.length > 0) { t.slKh += 1 };
            if(kh.aro=="x"){t.aro+=1;}
        })
        setTotal({ ...t })
    }, [data]);
   
    return (
        <section className='p-1 '>
            <Row gutter={16}>
    <Col span={12}>
      <Card bordered={false}>
        <Statistic
          title={<p className=' text-center text-black'>Khách hàng</p>}
         value={" "}
          precision={0}
          valueStyle={{
            color: '#3f8600',
            textAlign:"center",
          }}
          prefix={<p className='text-center w-full'>sl:{data.length} | aro: {total.aro}</p>}
          suffix=""
        />
      </Card>
    </Col>
    <Col span={12}>
      <Card    onClick={()=>{navigate("/congNo")}}  bordered={false}>
        <Statistic
     
          title={<p className='text-center text-black'>CÔNG NỢ</p>}
          value={` `}
          precision={0}
          valueStyle={{
            color: '#3f8600',
            textAlign:"center",
          }}
          prefix={<p className=' text-lg'>kh:{total.slKh} | <span className=' font-semibold text-red-500'>{formatCurrency(total.money)}</span></p>}
          suffix=""
        />  
      </Card>
    </Col>
   
  </Row>
        </section>
    )
}

export default Total