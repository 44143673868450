import React, { useEffect, useState, useMemo } from 'react';
import { checkRes } from '../../../unity';
import { spa_api_2024 } from '../../services/spa_api.service';
import { Input, Button, Modal, Form, Select, notification, DatePicker, Card, Collapse, List, Typography, Table, Col, Row } from "antd";
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import dayjs from 'dayjs';
import Loading from '../../components/Loading';
import BirthdatePicker from './BirthdatePicker';
import { EnvironmentOutlined, PhoneOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

const SpaPage = () => {
  const [customers, setCustomers] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [isQuaTang, setIsQuaTang] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm(); // Ant Design's useForm hook

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    spa_api_2024.get("user", { include: [{ as: "hoaDons", include: [{ as: "chiTietHoaDons", include: [{ as: "lieuTrinh" }] }] }] })
      .then((res) => {
        let value = checkRes(res);
        if (value) {
          setCustomers(value);
        } else {
          throw res;
        }
      })
      .catch((error) => console.error("Error fetching customers:", error));

    spa_api_2024.get("lieuTrinh")
      .then((res) => {
        let value = checkRes(res);
        if (value) {
          setTreatments(value);
        } else {
          throw res;
        }
      })
      .catch((error) => console.error("Error fetching treatments:", error));
  }, []);

  const filterCustomers = useMemo(() => {
    return phoneFilter !== "" ? customers.filter(customer => customer.phone.includes(phoneFilter)) : customers;
  }, [phoneFilter, customers]);

  const handleCheckIn = () => {
    setIsModalVisible(true);
    form.resetFields(); // Reset form values on opening modal
    setSelectedPhone("");
    setIsQuaTang("")
  };

  const handleCheckInSubmit = (values) => {
    setIsLoading(true);
    const { phone, name, address, birthday, treatment, dayCreate,note } = values;
    const customerExists = customers.find(c => c.phone === phone);

    if (customerExists) {
      // Existing customer check-in
      let dataHdTangQua = "";
      if (isQuaTang === 1) {
        dataHdTangQua = customerExists.hoaDons.filter((item, index) => {
          return item.type === ""
        })
          .map((item, index) => { if (index <= 5) { return { ...item, type: moment().format("DD/MM/YYYY") } } else return null })
          .filter((item) => { return item })
      }
      //

      let hoaDon = {
        idUser: customerExists.idUser,
        dayCreate: dayjs(dayCreate).format("DD/MM/YYYY"),
        note,
        chiTietHoaDons: [{
          idLt: treatment,
          lieuTrinh: {
            name: treatments.find((item) => item.idLt === treatment)?.name || "",
          }
        }],
        type: (isQuaTang == 0 || isQuaTang == "") ? "" : isQuaTang,
        log: isQuaTang ? JSON.stringify(dataHdTangQua) : "",
      };

      spa_api_2024.post(`hoaDon`, "post", hoaDon, { include: [{ as: "chiTietHoaDons" }] })
        .then((res) => {
          setIsLoading(false);
          let value = checkRes(res);
          if (value) {
            notification.success({ message: `Check-in thành công cho ${customerExists.name}` });
            setIsModalVisible(false);
            //
            if (isQuaTang == 1) {
              setHoaDonQuaTang(customerExists, dataHdTangQua);
            }
            // Update customer's hoaDon
            let cloneCus = [...customers];
            let index = cloneCus.findIndex((item) => item.idUser === customerExists.idUser);
            cloneCus[index].hoaDons = [...cloneCus[index].hoaDons, hoaDon];
            setPhoneFilter(phone);
            setCustomers(cloneCus);
          } else {
            throw res;
          }
        })
        .catch((err) => {
          notification.error({ message: "Check-in failed!" });
          setIsLoading(false);
        });
    } else {
      // New customer check-in
      let newUser = {
        phone,
        name,
        address,
        birthday: dayjs(birthday).format("DD/MM/YYYY"),
        role: "customer",
        hoaDons: [{
          note,
          dayCreate: dayjs(dayCreate).format("DD/MM/YYYY"),
          chiTietHoaDons: [{
            idLt: treatment,
            lieuTrinh: {
              name: treatments.find((item) => item.idLt === treatment)?.name || "",
            }
          }],
          type: isQuaTang === 1 ? isQuaTang : "",
        }]
      };

      spa_api_2024.post("user", "post", newUser, { include: [{ as: "hoaDons", include: [{ as: "chiTietHoaDons" }] }] })
        .then((res) => {
          setIsLoading(false);
          let value = checkRes(res);
          if (value) {
            notification.success({ message: "New customer checked in successfully!" });
            setIsModalVisible(false);
            setCustomers([...customers, value]);
            setPhoneFilter(phone);
          } else {
            throw res;
          }
        })
        .catch((err) => {
          notification.error({ message: "Check-in failed!" });
          setIsLoading(false);
        });
    }
  };
  const setHoaDonQuaTang = (customerExists, data) => {

    // console.log(data);
    spa_api_2024.post("hoaDon", "put", data).then((res) => {
      let value = checkRes(res);
      console.log(res);
      if (value) {
        let index = customers.findIndex((item) => { return item.idUser == customerExists.idUser });
        let clone = [...customers];
        clone[index].hoaDons = clone[index].hoaDons.map((item) => {
          let matchHd = value.find((itemVl) => { return itemVl.idHd == item.idHd }) ?? item
          return matchHd;
        })
        setCustomers(clone)
      } else {
        console.log(res);
      }
    })
  }
  useEffect(() => {
    let user = customers.find((cus) => { return cus.phone === selectedPhone });
    let check = user?.hoaDons.filter(item => item.type === "").length >= 6;
    if (check) {
      setIsQuaTang(1);
      form.setFieldValue("isQuaTang", 1);
    } else {
      setIsQuaTang(0);
      form.setFieldValue("isQuaTang", 0);
    }

  }, [selectedPhone])
  const renderNewUser = useMemo(
    () => {
      let user = customers.find((cus) => { return cus.phone === selectedPhone });

      return !user && selectedPhone != "" && selectedPhone.length == 10 ?
        <>
          <Form.Item label="Tên khách hàng" name="name" rules={[{ required: true, message: 'Nhập tên!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Địa chỉ" name="address" rules={[{ required: true, message: 'Nhập địa chỉ!' }]}>
            <Input />
          </Form.Item>

          <BirthdatePicker form={form}></BirthdatePicker>
        </> : <></>
    },
    [selectedPhone],
  )
  const getMatchingHoaDons = (hoadon) => {
    try {
      const parsedLogs = JSON.parse(hoadon.log); // Parse the log
      return parsedLogs.filter(logItem => logItem.idHd === hoadon.idHd);
    } catch (e) {
      console.error("Failed to parse log:", e);
      return [];
    }
  };

  const getColorByDate = (dayCreate) => {
    // Tạo mã màu dựa trên ngày tặng quà
    const dateValue = moment(dayCreate, "DD/MM/YYYY").format('YYYYMMDD');
    const colorHash = parseInt(dateValue) % 360; // Chia ngày thành một giá trị hue trong HSL
    // console.log(`hsl(${colorHash}, 70%, 80%)`);
    return `hsl(${colorHash}, 70%, 80%)`; // Trả về mã màu HSL
  };


  return (
    customers.length > 0 ? (
      <div className={` p-4 ${isModalVisible ? 'overflow-hidden' : "overflow-scroll"}`}>
        <Title level={2} className="text-center">Hệ Thống Check-In Her's Spa</Title>
        <div className="border rounded-md flex m-2 justify-center space-x-2 capitalize">
          <Card title="Tổng khách">
            <p className="text-center font-bold text-xl">{customers.length}</p>
          </Card>
          <Card title="Thông báo Quà tặng">
            {customers.map(cus => {
              let check = cus.hoaDons.filter(item => item.type === "").length >= 6;

              return check ? <p className="animate-pulse text-green-900">{cus.name} - {cus.phone}</p> : null;
            })}
          </Card>
        </div>

        <div className="my-2">
          <Input
            placeholder="Tìm theo số điện thoại"
            value={phoneFilter}
            onChange={(e) => setPhoneFilter(e.target.value)}
            className="mb-4"
          />
          <Button onClick={handleCheckIn}>CHECKIN</Button>
          {phoneFilter !== "" && <Button onClick={() => setPhoneFilter("")} className="capitalize mx-2">Hiển thị tất cả khách hàng</Button>}
        </div>

        {isMobile ? (
          <Row gutter={[16, 16]}>
            {filterCustomers.map(customer => (
              <Col span={24} key={customer.idUser}>
                <Card className="border shadow-md mb-4 bg-neutral-100 " title={customer.name} bordered={false}>
                  <div className='pl-2'>
                    <p><PhoneOutlined></PhoneOutlined> : {customer.phone}</p>
                    <p><EnvironmentOutlined />: {customer.address}</p>
                    <p><ScheduleOutlined />: {customer.birthday}</p>
                    <p>Số lần đến: {customer.hoaDons.length}</p>
                  </div>
                  <Collapse>
                    <Panel header="Hiển thị chi tiết" key={customer.idUser}>
                      {customer.hoaDons.map(hoadon => {
                        const matchingHoaDons = getMatchingHoaDons(hoadon);

                        // Kiểm tra nếu type === 1 (quà tặng)
                        const quaTang = hoadon.type === 1 || matchingHoaDons.some(item => item.type === 1);
                        const bgColor = quaTang ? getColorByDate(hoadon.dayCreate) : getColorByDate(hoadon.type); // Màu nền dựa trên ngày tặng quà

                        return (
                          <List
                            key={hoadon.idHd}
                            itemLayout="horizontal"
                            dataSource={hoadon.chiTietHoaDons}
                            renderItem={item => (
                              <List.Item
                                className=' rounded-md'
                                style={{ backgroundColor: bgColor }} // Áp dụng màu nền tuỳ thuộc vào log và ngày tặng quà
                              >
                                <List.Item.Meta
                                  className=' p-1'

                                  title={
                                    <div className=' flex space-x-2'>
                                      <p>
                                        Ngày đến: {hoadon.dayCreate || "Chưa có dữ liệu"}
                                      </p>
                                      {quaTang && (
                                        <p className='text-blue-500 animate-bounce'>
                                          Quà Tặng
                                        </p>
                                      )}
                                    </div>
                                  }
                                  description={
                                  <>
                                    <p>
                                      Liệu trình: {item.lieuTrinh.name || "Chưa có liệu trình"} | Giá: {item.lieuTrinh.price || "Chưa có giá"}
                                    </p>
                                    {hoadon.note &&
                                      <p>
                                      ghi chú: {hoadon.note}
                                    </p>}
                                  </>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        );
                      })}
                    </Panel>
                  </Collapse>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          // <Table
          //   columns={[
          //     { title: "Tên", dataIndex: "name", key: "name" },
          //     { title: "Số điện thoại", dataIndex: "phone", key: "phone" },
          //     { title: "Địa chỉ", dataIndex: "address", key: "address" },
          //     { title: "Ngày sinh", dataIndex: "birthday", key: "birthday" },
          //     {
          //       title: "Số lần đến (Hóa đơn)",
          //       key: "hoaDons",
          //       render: (_, customer) => customer.hoaDons.length,
          //     },
          //   ]}
          //   dataSource={customers}
          //   rowKey="idUser"
          //   expandable={{
          //     expandedRowRender: customer => (
          //       <Collapse>
          //         {customer.hoaDons.map(hoadon => (
          //           <Panel header={`Ngày đến: ${hoadon.dayCreate || "Chưa có dữ liệu"}`} key={hoadon.idHd}>
          //             <List
          //               itemLayout="horizontal"
          //               dataSource={hoadon.chiTietHoaDons}
          //               renderItem={item => (
          //                 <List.Item >
          //                   <List.Item.Meta
          //                   className="bg-blue-500 text-red-600"

          //                     title={item.lieuTrinh.name}
          //                     description={`Giá: ${item.lieuTrinh.price || "Chưa có giá"}`}
          //                   />
          //                 </List.Item>
          //               )}
          //             />
          //           </Panel>
          //         ))}
          //       </Collapse>
          //     ),
          //   }}
          // />
          <></>
        )}

        <div>
          <Modal
            className='w-screen h-2/3'
            title="Check-in Khách Hàng"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
          >
            <Form
              className='w-full h-full overflow-scroll'
              form={form} layout="vertical" onFinish={handleCheckInSubmit}>
              <Form.Item label="Số điện thoại" name="phone" rules={[{ required: true, message: 'Nhập số điện thoại!' }]}>
                <Input onChange={(e) => { setSelectedPhone(e.target.value) }} />
              </Form.Item>
              {selectedPhone != "" && customers.find((cus) => { return cus.phone === selectedPhone }) &&
                <div className=' capitalize m-2'>
                  <p><UserOutlined />: {customers.find((cus) => { return cus.phone === selectedPhone }).name}</p>
                  <p><PhoneOutlined />: {customers.find((cus) => { return cus.phone === selectedPhone }).phone}</p>
                </div>
              }
              {renderNewUser}
              <Form.Item initialValue={dayjs(moment(new Date()))} label="Ngày đến" name="dayCreate" rules={[{ required: true, message: 'Chọn ngày đến!' }]}>
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item label="Liệu trình" name="treatment" rules={[{ required: true, message: 'Chọn liệu trình!' }]}>
                <Select placeholder="Chọn liệu trình">
                  {treatments.map(item => (
                    <Option key={item.idLt} value={item.idLt}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Check-in kiểu quà tặng" name="isQuaTang">
                <Select value={isQuaTang}

                  onChange={value => {
                    console.log(value);
                    setIsQuaTang(value)
                    form.setFieldValue("isQuaTang", 0)
                  }}>

                  <Option value={0}>Không có</Option>
                  <Option value={1}>Quà tặng</Option>
                </Select>
                {isQuaTang == 1 && <p className=' animate-bounce text-green-600 my-2'>
                  khách hàng đủ tiêu chí tặng quà !!! <br></br>
                  (có thể đổi nếu không tặng)
                </p>}
              </Form.Item>
              <Form.Item label="ghi chú" name="note" >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type='primary' className='bg-blue-400 animate-pulse' htmlType="submit" loading={isLoading}>
                  Check-in
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    ) : (
      <Loading />
    )
  );
};

export default SpaPage;
