import axios from "axios";
import { userInforLocal } from "./local.service";
let uri="https://script.google.com/macros/s/AKfycbxL8Rr_w-Bi18sj_LDYQXrQfuOH6_ijpLFjjTmsFT4I4M5XoW29lmZ00zbK9bX-DY2q4A/exec?action=v1/"


// let uri="https://script.google.com/macros/s/AKfycbyJJhqka7-9B48NQ_gJ0Pxuz-JCpnENsWkWHnU3-kPvJ5DTjqpUNROoo_6cIcfQe6O8Tg/exec?action=v1/"
let configUri=(stringAction ,query)=>{
   return uri+`${stringAction}`+`${query?`&query=${JSON.stringify(query)}`:``}`+`${`&token=${""}`}`;
}
export let spa_api_2024={   
   get:(action,query)=>{
      let url=configUri(action,query); 
      return axios.get(url);
   },
   post:(action,method,data,query=null)=>{
      let obj={method,data,token:""};
      // console.log(JSON.stringify(obj));
      let url=configUri(action,query);
      return axios.post(url,JSON.stringify(obj))
   },
   put:({id,nameSheet,data})=>{
      let url=uri+'put'; 
      return axios.post(url,JSON.stringify({id,nameSheet,data}))
   },
}
